.Page_ConfigDash .list_opt_idioma{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;  
    margin-bottom: 16px;
}
.Page_ConfigDash .list_opt_idioma .div_idioma{
    width: 100px;
    text-align: center;
    padding: 8px 16px;
    border: 1px solid #2D3C53;
    border-radius: 6px;
    cursor: pointer;
}
.Page_ConfigDash .list_opt_idioma .idioma_active, .Page_ConfigDash .list_opt_idioma .div_idioma:hover{
    background-color: #2D3C53;
    color: #ffffff;
}