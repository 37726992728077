.PopUp .show_file {
    width: 760px;
}
.PopUp .show_img{
    max-width: 100%;
    max-height: 600px;
}
.PopUp .div_video{
    width: -webkit-fill-available;
    height: 400px;
}
